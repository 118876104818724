<template>
  <div class="diadin pd" v-loading.fullscreen.lock="loading" @scroll="handleScroll">
    <!-- 头部标题 -->
    <div style="width: 100%;">
      <div style="display: flex;width: 100%;align-items: center; justify-content: space-between;margin-bottom: 10px;">
        <div>
          <span style="display: inline-block;width: 4px;background-color: #4975e9;height: 19px;margin-right: 5px;"></span>
          <span class="font-18 font-bold" style="line-height: 15px;font-family: PingFang SC-Bold;color: #333;">{{ pagetitle }}</span>

        </div>
        <!-- <div style="display: flex;"  v-if="this.rxmk==false" >
                <div style="margin-right: 30px;">统计时间 <span style="margin-left: 4px;">03/21-03/22</span></div>
                <div>
                  <span @click="biao22(0)">昨日</span>
                  <el-divider direction="vertical"></el-divider>
                  <span @click="biao22(1)">近7日</span>
                  <el-divider direction="vertical"></el-divider>
                  <span @click="biao22(2)">近30日</span>
                 
                </div>
            </div> -->
      </div>
    </div>

    <!-- 筛选搜索 -->
    <div style="width: 100%;padding: 20px;padding-right: 0;display: flex;padding-bottom: 0; padding-left: 0; align-items: center; flex-wrap: wrap;">
      <div style="display: flex; align-items: center;width: 100%;margin-bottom: 10px;">
        <div class="titlable" style="width: 112px;">
          关键词搜索:
        </div>
        <div style="width:100%;position: relative;">
          <el-input style="width: 100%;" v-model="formInline.title" suffix-icon="el-icon-search" placeholder="请选择搜索条件">

          </el-input>

        </div>

        <div class="titlable" style="width: 112px;">
          品类选择:
        </div>
        <div style="width:100%;position: relative;">
          <el-cascader style="width: 100%;" placeholder="请选择搜索条件" :props="{value:'id', label: 'nameCn', children: 'children' ,multiple: true}" :options="plfllist" clearable v-model="formInline.categoryList" filterable @change='changeCascader' :show-all-levels="false" collapse-tags></el-cascader>
        </div>
        <!-- <div style="width:100%;position: relative;" v-else="showLanguage">
          <el-cascader style="width: 100%;" placeholder="请选择搜索条件" :props="{value:'id', label: 'nameEn', children: 'children' ,multiple: true}" :options="plEnList" clearable v-model="formInline.categoryList" filterable @change='changeCascader' :show-all-levels="false" collapse-tags></el-cascader>
        </div> -->

        <div v-if="type==3||type==4" class="titlable">
          市场年龄
        </div>
        <div v-if="type==3||type==4" style="width:100%;position: relative;">
          <!-- <el-input v-model="formInline.marketAge"  placeholder="xxxx-xx-xx" ></el-input> -->
          <el-select v-model="formInline.marketAge" placeholder="xxxx-xx-xx">
            <el-option v-for="(e,i) in scnllist" :key="i" :label="e.title" :value="e.value" :disabled="e.disabled"></el-option>
          </el-select>
        </div>

        <div class="titlable" style="width: 112px;" v-if="type==1||type==2">
          上架时间:
        </div>
        <div style="width:100%;position: relative;" v-if="type==1||type==2">
          <el-date-picker :disabled="scpd" v-model="formInline.data1" type="daterange" align="right" :clearable="false" unlink-panels format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="(type == 1 & status == 3) ?  pickerOptionsNew : pickerOptions">
          </el-date-picker>

        </div>
      </div>

      <div v-if="showMore" style="width: 100%;padding: 20px;padding-right: 0;display: flex; padding-top: 0;  padding-left: 0;  align-items: center; flex-wrap: wrap;">
        <div style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            总销量
          </div>
          <div style="width:100%;display: flex; align-items: center;   ">
            <el-input v-model="formInline.minSales" type="number" placeholder="最低" :min="0" @blur="changeNum(1)"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxSales" type="number" placeholder="最高" :min="0" @blur="changeNum(2)"></el-input>
          </div>
        </div>
        <div style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;" v-if="type==1||type==2">
          <div class="titlable">
            价格
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minPrice" type="number" placeholder="最低" :min="0" @blur="changeNum(3)"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxPrice" type="number" placeholder="最高" :min="0" @blur="changeNum(4)"></el-input>
          </div>
        </div>
        <!-- <div v-if="type==1||type==2" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            关联店铺
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minSaleAmount" type="number" placeholder="最低"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxSaleAmount" type="number" placeholder="最高"></el-input>
          </div>
        </div> -->
        <!-- <div v-if="type==1||type==2" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            市场年龄
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-select :disabled="scpd1" v-model="formInline.marketAge" placeholder="xxxx-xx-xx">
              <el-option v-for="(e,i) in scnllist" :key="i" :label="e.title" :value="e.value" :disabled="e.disabled"></el-option>

            </el-select>
            <!-- <el-input v-model="formInline.marketAge"  placeholder="xxxx-xx-xx" ></el-input>
          </div>
        </div> -->
        <div v-if="type==1||type==2" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            30天销量
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minMonthSold" type="number" placeholder="最低" :min="0" @blur="changeNum(5)"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxMonthSold" type="number" placeholder="最高" :min="0" @blur="changeNum(6)"></el-input>
          </div>
        </div>
        <div v-if="type==1||type==2" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            评分
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minScore" type="number" placeholder="最低" :min="0" @blur="changeNum(7)"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxScore" type="number" placeholder="最高" :min="0" @blur="changeNum(8)"></el-input>
          </div>
        </div>
        <!-- <div v-if="type==1||type==2" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            粉丝数
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minFollwers" type="number" placeholder="最低"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxFollwers" type="number" placeholder="最高"></el-input>
          </div>
        </div> -->
        <!-- <div v-if="type==1||type==2" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            评论数
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minComments" type="number" placeholder="最低"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxComments" type="number" placeholder="最高"></el-input>
          </div>
        </div> -->
        <div v-if="type==3||type==4" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            总销售额
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minSoldAmount" type="number" placeholder="最低" @blur="changeNum(9)"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxSoldAmount" type="number" placeholder="最高" @blur="changeNum(10)"></el-input>
          </div>
        </div>
        <div v-if="type==3||type==4" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            商品数量
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minItems" type="number" placeholder="最低" @blur="changeNum(11)"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxItems" type="number" placeholder="最高" @blur="changeNum(12)"></el-input>
          </div>
        </div>

        <!-- <div v-if="type==3||type==4" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            店铺数量
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minShopNum" type="number" placeholder="最低"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxShopNum" type="number" placeholder="最高"></el-input>
          </div>
        </div> -->
        <div v-if="type==3||type==4" style="display: flex; align-items: center;width: 25%;margin-bottom: 10px;">
          <div class="titlable">
            平均客单价
          </div>
          <div style="width:100%;display: flex; align-items: center;">
            <el-input v-model="formInline.minAvgPrice" type="number" placeholder="最低"></el-input>
            <div style="margin: 0 5px; color: #333333;">-</div>
            <el-input v-model="formInline.maxAvgPrice" type="number" placeholder="最高"></el-input>
          </div>
        </div>
      </div>
      <div style="margin-left:60px">
        <el-button class="font-15" type="text" @click="showMore = !showMore">
          {{ showMore ? '收起' :'展开'}}
          <i :class="showMore ? 'el-icon-caret-top' :'el-icon-caret-bottom'" style="margin-right:8px"></i>
        </el-button>
      </div>
    </div>

    <div style="display: flex;justify-content:flex-end;align-items: center;">

      <div @click="biaoaa()" class="font-14" style="cursor: pointer; background-color:#4974e917;margin-right: 25px;font-family: PingFang SC-Regular; border: 1px solid #4974e97a;color: #4975E9;border-radius: 5px;padding: 3px 7px;"><i class="el-icon-search"></i> 搜索</div>
      <div @click="qxcz()" class="font-14" style="cursor: pointer; background-color:rgba(51, 51, 51, 0.096);font-family: PingFang SC-Regular; border: 1px solid rgba(51, 51, 51, 0.459);color: #333;border-radius: 5px;padding: 3px 7px;"><i class="el-icon-delete"></i> 清空</div>
      <!-- <el-button type="primary" plain @click="biao()"> <i class="el-icon-search"></i> 搜索</el-button>

        <el-button plain type="info" @click="qxcz()"><i class="el-icon-delete"></i> 清空</el-button> -->

    </div>
    <div style="display: flex;align-items: center;margin: 15px;margin-left: 0;    justify-content: space-between;">

      <div style="display: flex;align-items: center;">
        <!-- <div class="font-16" style="color: #333333;margin-right:10px">
          {{ defaultActivename }}列表
        </div> -->
        <div v-if="type==1">
          <el-switch v-model="showLanguage" class="pd-search-switch" inactive-color="#F56C6C" active-text="中文" inactive-text="英文">
          </el-switch>
        </div>
      </div>
      <div v-if="type==3" style="margin-left:200px ;">

        <el-button type="primary" @click="exportRecords"> <i class="el-icon-download"></i> 导出</el-button>

      </div>
    </div>
    <!-- 表格 -->
    <div :class=" stickyFlag ? 'pd-table' : '' ">

      <el-table v-if="type==1" :data="tableData" :border='true' style="width: 100%;position: sticky;top:0" stripe @sort-change="sortByDate" ref='table' :default-sort="{'prop': `prop`,'order':type}">
        <el-table-column prop="name" label="商品" width="350" align="center">
          <template slot-scope="scope">
            <div style="display: flex;text-align: left;">
              <div style="width: 77px;height:77px;margin-right: 21px;">
                <el-popover placement="top" trigger="hover">
                  <div>
                    <img style="width: 150px;height:150px;border-radius: 10px;" :src=" (scope.row.imageUrl ? scope.row.imageUrl : require('../../assets/imgs/empty.png')) | image(150,150) " alt="">
                  </div>
                  <div class="same-pic-content-list-item-pic" slot="reference">
                    <img style="width: 77px;height:77px;border-radius: 10px;" :src=" (scope.row.imageUrl ? scope.row.imageUrl : require('../../assets/imgs/empty.png')) | image(150,150)" alt="">
                  </div>
                </el-popover>
              </div>
              <div style="width:600px;">
                <el-popover placement="top-start" width="350" trigger="hover" :content="scope.row.name">

                  <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                  <span slot="reference" @click="spxq(scope.row.id)" class="font-10" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;cursor: pointer;">
                    {{ showLanguage ? (scope.row.name || scope.row.productEnName || '') : (scope.row.productEnName || scope.row.name   || '')  }}
                  </span>

                </el-popover>

                <div style="margin-top: 6px;display:flex;align-items:center;height:28px;flex-wrap:wrap">

                  <span @click="spxq(scope.row.id)" class="font-11" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 1px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;min-width:80px"><i class="el-icon-link"></i>商品详情</span>
                  <div v-if="scope.row.hostingMode && [1,2,'1','2'].includes(scope.row.hostingMode)" style="height:28px">
                    <el-image fit='contain' :src=" ( scope.row.hostingMode == 1 ? 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_all.png' : 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_half.png' ) | image(64,30) " style="width:64px;height:28px;border-radius:18px"></el-image>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="secondCategory" label="品类" width="200" style="color:#333;font-weight: 400;">
          <template slot-scope="scope">
            <div style="padding: 7px 0;display: flex;justify-content:center;align-items:center">
              <el-tooltip class="item" effect="dark" :content="scope.row.secondCategory.join('>')" placement="top">
                <span class="font-10" style="overflow: hidden;text-overflow: ellipsis; white-space: nowrap;box-sizing: border-box; line-height: 17px; cursor: pointer; background-color: #4974e91f;padding: 4px 6px;color:#4975E9;border-radius: 5px;border: 1px solid #4975E9;">{{ scope.row.secondCategory[scope.row.secondCategory.length-1] }}</span>

              </el-tooltip>
            </div>

          </template>
        </el-table-column>
        <el-table-column align="center" prop="goodsTrends" label="销量趋势" width="130">
          <template slot-scope="scope">
            <div v-if='scope.row.goodsTrends && scope.row.goodsTrends.length > 0'>
              <tendency :list="scope.row.goodsTrends" :id="scope.row.id"></tendency>
            </div>
            <div v-else>
              <i class="el-icon-warining"></i>收录中
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="price" align="center" label="价格" sortable='custom'>
          <template slot-scope="scope">
            <div style="width: 100%;">
              <div style="color:#E34D59;width: 100%;">{{scope.row.currencySymbol}}{{scope.row.price  | formatMoney}} </div>
              <!-- <div style="color: red;" v-if="scope.row.currencySymbol>0">{{ scope.row.currencySymbol }}<img style="width: 12px;height: 12px;" src="../../assets//images/shang.png" alt=""></div>
              <div style="color: #17FFB2;" v-if="scope.row.currencySymbol<0">{{ scope.row.currencySymbol }}<img style="width: 12px;height: 12px;" src="../../assets//images/xia.png" alt=""></div> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="score" align="center" label="商品评分" sortable='custom'>
          <template slot-scope="scope">
            <div style="font-weight: bold;">
              {{ scope.row.score ? scope.row.score  : '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="销售量">
          <el-table-column prop="sales" align="center" label="总销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   scope.row.sales | formatSales}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="dailySold" align="center" label="日销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   (scope.row.dailySold || 0) | formatSales}}
              </div>
              <div>
                <span>
                  <i class="el-icon-caret-top" style="color:red" v-if="scope.row.dailySoldInerease > 0"></i>
                  <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                </span>
                {{ scope.row.dailySoldInerease || '0.00'  | formatPercent}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="weekSold" align="center" label="周销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   (scope.row.weekSold || 0) | formatSales}}
              </div>
              <div>
                <span>
                  <i class="el-icon-caret-top" style="color:red" v-if="scope.row.weekSoldIncrease > 0"></i>
                  <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                </span>
                {{ scope.row.weekSoldIncrease || '0.00'  | formatPercent}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="monthSold" align="center" label="月销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   (scope.row.monthSold || 0) | formatSales}}
              </div>
              <div>
                <span>
                  <i class="el-icon-caret-top" style="color:red" v-if="scope.row.monthSoldIncrease > 0"></i>
                  <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                </span>
                {{ scope.row.monthSoldIncrease || '0.00'  | formatPercent}}
              </div>
            </template>
          </el-table-column>

        </el-table-column>
        <!-- <el-table-column prop="sales" align="center"  label="关联店铺"  >
          <template slot-scope="scope">
            <div style="width: 100%;">
             {{scope.row.sales}}
               
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="onlineTime" align="center" label="市场年龄" sortable='custom'> </el-table-column>
      </el-table>
      <el-table v-if="type==2" :data="tableData" style="width: 100%" @sort-change="sortByDate" ref='table' :default-sort="{'prop': `prop`,'order':type}">
        <el-table-column prop="title" label="店铺" width="300" align="center">
          <template slot-scope="scope">
            <div style="display: flex;text-align: left;padding: 11px 0;">
              <div style="width: 77px;height:77px;margin-right: 21px;">
                <img style="width: 77px;height:77px;border-radius: 10px;" :src=" (scope.row.cover ? scope.row.cover  : require('../../assets/imgs/empty.png') ) | image(77,77)" alt="">
              </div>
              <div style="width: 420px;">
                <div style="color:#013bfe;">
                  <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                  <span class="font-10" @click="details()" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ scope.row.title }}</span>
                </div>
                <div style="margin-top: 6px;display:flex;height:28px;flex-wrap:wrap">

                  <span class="font-11" @click="shopid(scope.row.id)" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 1px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;min-width:80px"><i class="el-icon-link"></i> 店铺链接
                  </span>
                  <div v-if="scope.row.hostingMode && [1,2,'1','2'].includes(scope.row.hostingMode)" style="height:28px">
                    <el-image fit='contain' :src=" ( scope.row.hostingMode == 1 ? 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_all.png' : 'https://hishowstatic.oss-cn-hangzhou.aliyuncs.com/kjxp/tuoguan_half.png' ) | image(64,30) " style="width:64px;height:28px;border-radius:18px"></el-image>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="销售量">
          <el-table-column prop="shopSold" align="center" label="总销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{  ( scope.row.shopSold || 0) | formatSales}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="dailySold" align="center" label="日销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   (scope.row.dailySold || 0) | formatSales}}
              </div>
              <div>
                <span>
                  <i class="el-icon-caret-top" style="color:red" v-if="scope.row.weekSoldIncrease > 0"></i>
                  <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                </span>
                {{ scope.row.dailySoldInerease || '0.00'  | formatPercent}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="weekSold" align="center" label="周销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   (scope.row.weekSold || 0) | formatSales}}
              </div>
              <div>
                <span>
                  <i class="el-icon-caret-top" style="color:red" v-if="scope.row.weekSoldIncrease > 0"></i>
                  <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                </span>
                {{ scope.row.weekSoldIncrease || '0.00'  | formatPercent}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="monthSold" align="center" label="月销量" sortable='custom'>
            <template slot-scope="scope">
              <div style="width: 100%;font-weight: bold;">
                {{   (scope.row.monthSold || 0) | formatSales}}
              </div>
              <div>
                <span>
                  <i class="el-icon-caret-top" style="color:red" v-if="scope.row.monthSoldIncrease > 0"></i>
                  <i class="el-icon-caret-bottom" style="color:green" v-else></i>
                </span>
                {{ scope.row.monthSoldIncrease || '0.00'  | formatPercent}}
              </div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column prop="shopSoldAmount" align="center" label="总销售额" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.shopSoldAmount || '0.00'  | formatMoney}}
          </template>

        </el-table-column>

        <el-table-column prop="items" align="center" label="商品数量" width="120" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.items || 0  | formatMoney}}
          </template>
        </el-table-column>
        <el-table-column prop="follower" align="center" label="粉丝数" width="120" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.follower || 0  | formatMoney}}
          </template>
        </el-table-column>
        <el-table-column prop="shopOnlineTime" align="center" label="开店时间" width="130" sortable='custom'> </el-table-column>
        <el-table-column prop="avg" align="center" label="平均客单价" width="140" sortable='custom'>
          <template slot-scope="scope">
            <div style="width: 100%;text-align: center;">
              <div style="color:#f5762c;">{{scope.row.avg | formatMoney }} </div>
              <div class="font-10">{{scope.row.chAvg | formatMoney }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="score" align="center" label="评分" sortable='custom'>
          <template slot-scope="scope">
            <div style="font-weight: bold;">
              {{ scope.row.score ? scope.row.score  : '--' }}
            </div>
          </template>
        </el-table-column>

      </el-table>
      <el-table v-if="type==3" :data="tableData" style="width: 100%" @sort-change="sortByDate" ref='table' :default-sort="{'prop': `prop`,'order':type}">
        <el-table-column prop="keyword" label="关键词" width="250" align="center">
          <template slot-scope="scope">
            <div style="display: flex;text-align: left;padding: 11px 0;">
              <div style="width: 77px;height:77px;margin-right: 21px;">
                <img style="width: 77px;height:77px;border-radius: 10px;" :src="(scope.row.thumbnail ? scope.row.thumbnail :  require('../../assets/imgs/empty.png')) | image(77,77)" alt="">
              </div>
              <div style="width: 420px;">
                <div style="color:#013bfe;">
                  <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                  <span @click="details()" class="font-10" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ scope.row.keyword }}</span>
                </div>
                <div style="margin-top: 6px;">

                  <span @click="llgl(scope.row)" class="font-10" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 2px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;"> <i class="el-icon-link"></i> <a :href="scope.row.shopLink" target="_blank">关联商品</a></span>

                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="secondCategory" align="center" label="品类">
          <template slot-scope="scope">

            <div style="padding: 7px 0;width: 220px;">
              <el-tooltip class="item" effect="dark" :content="scope.row.secondCategory.join('>')" placement="top">
                <span class="font-10" style="overflow: hidden;text-overflow: ellipsis; white-space: nowrap;box-sizing: border-box; line-height: 17px; cursor: pointer; background-color: #4974e91f;padding: 4px 6px;color:#4975E9;border-radius: 5px;border: 1px solid #4975E9;">{{ scope.row.secondCategory[scope.row.secondCategory.length-1] }}</span>

              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="dsr" align="center" label="供需比" sortable='custom'>
          <template slot-scope="scope">
            <div style="width: 100%; ">
              <el-progress :percentage="scope.row.dsr/500 > 100 ? 100 : scope.row.dsr/500" :show-text="false" define-back-color="#fff"></el-progress>
              <div>{{ scope.row.dsr }}</div>

            </div>
          </template>
        </el-table-column>

        <el-table-column prop="itemCount" align="center" label="商品数量" width="120" sortable='custom'> </el-table-column>
        <el-table-column prop="mallCount" align="center" label="店铺数量" width="120" sortable='custom'> </el-table-column>
        <el-table-column prop="createTime" align="center" label="市场年龄" width="130" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.createTime.slice(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="avg_price" align="center" label="平均客单价" width="140" sortable='custom'="custom">
          <template slot-scope="scope">
            <div style="width: 100%;">
              <div style="color:#f5762c;">{{scope.row.avgPrice }} </div>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <el-table v-if="type==4" :data="tableData" style="width: 100%" @sort-change="sortByDate" ref='table'>
        <el-table-column prop="catName" label="行业" width="250" align="center">
          <template slot-scope="scope">
            <div style="display: flex;text-align: left;padding: 11px 0;">
              <div style="width: 77px;height:77px;margin-right: 21px;">
                <img style="width: 77px;height:77px;border-radius: 10px;" :src="( scope.row.thumbnail ? scope.row.thumbnail  :  require('../../assets/imgs/empty.png') ) | image(77,77)" alt="">
              </div>
              <div style="width: 800px;">
                <div style="color:#013bfe;">
                  <!-- <span style="background-color: #e5f2fd;padding: 2px 5px;margin-right: 5px;">{{ scope.row.category }}</span> -->
                  <span @click="details()" class="font-10" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ scope.row.catName }}</span>
                </div>
                <div style="margin-top: 6px;">

                  <span @click="scgl(scope.row)" class="font-10" style=" border: 1px solid #4974e96c;border-radius: 5px; background-color: #4974e91f;padding: 2px 9px;margin-right: 5px;color:#4975E9;cursor: pointer;"> <i class="el-icon-link"></i> <a :href="scope.row.shopLink" target="_blank">关联商品</a></span>

                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="dsr" align="center" label="供需比" sortable='custom'>
          <template slot-scope="scope">
            <div style="width: 100%; text-align: center">
              <el-progress :percentage="scope.row.ratio * 100" :show-text="false"></el-progress>
              <div>{{ parseFloat(scope.row.ratio * 100 ).toFixed(2)  | formatPercent }}</div>

            </div>
          </template>
        </el-table-column>
        <el-table-column prop="itemCount" align="center" label="商品数量" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.itemCount || 0  | formatMoney}}
          </template>
        </el-table-column>
        <el-table-column prop="mallCount" align="center" label="店铺数量" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.mallCount || 0  | formatMoney}}
          </template>
        </el-table-column>
        <el-table-column prop="totalSold" align="center" label="总销量" sortable='custom'>
          <template slot-scope="scope">
            {{ scope.row.totalSold || 0  | formatMoney}}
          </template>
        </el-table-column>
        <el-table-column prop="avg_price" align="center" label="平均客单价" sortable='custom'>
          <template slot-scope="scope">
            <div style="width: 100%;text-align: center;">
              <div style="color:#f5762c;">{{scope.row.avgPrice  | formatMoney }} </div>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div style="margin: 10px auto;text-align: center;display:flex;justify-content:center;align-items:center">
        <span style="margin-right:10px">共<span style="padding:0 10px">{{ total >= 10000 ? '10000+' : total}}</span>条</span>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="curPage" :page-sizes="[10, 20, 30,50]" :page-size="pageSize" layout=" prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 分页 -->
    <el-dialog :visible.sync="show_vip" width="20%" custom-class="dialogwidth" :show-close='true'>
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未开通会员，请购买开通</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toBuy">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showLogin" width="20%" custom-class="dialogwidth">
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未登录，请登录</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script> 
import { listHotSaleProducts, initCategory, initAge, exportRecords, listDaySaleProducts, listHotNewProducts, listPotentialProducts, listHotSaleShop, listPersistHotSaleShop, listNewShopSale, getSearchWordsList, marketlist, itemac, agetSaleAmtAndSumTrend, getUserInfo } from '../../api/api'
import tendency from '../../components/tendency';
import { Base64 } from 'js-base64';
export default {
  components: {
    tendency
  },
  data() {
    return {
      formInline: {
        sort: 'desc',
        data1: '',
        data2: '',
        source: "",
        title: "",
        marketAge: '',
        minPrice: "",
        maxPrice: "",
        categoryList: [],
        minSales: "",
        maxSales: "",
        minScore: "",
        maxScore: "",
        minComments: "",
        maxComments: "",
        minOnlineTime: "",
        maxOnlineTime: "",
        field: '',
        minAvg: "",
        maxAvg: "",
        minItems: "",
        maxItems: "",
        maxMonthSold: '',
        minMonthSold: '',
        firstCategory: '',
        secondCategory: '',
      },
      select: '',

      rxmk: false,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      upproject: false,
      Conditions: false,
      dialogVisible: false,
      value1: '',
      radio2: 3,
      finishId: '',
      field: '',
      scpd: false,
      scpd1: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      sort: 'desc',
      pickerOptionsNew: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
        disabledDate(time) {
          const begin = new Date()
          const now = begin.setMonth(begin.getMonth() - 3);
          return time.getTime() < now; //只能选择今天及今天之前的日期
        }
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
        disabledDate(time) {
          const begin = new Date()
          const now = begin.setMonth(begin.getMonth() - 6);
          return time.getTime() < now; //只能选择今天及今天之前的日期
        }
      },
      tableData: [
      ],
      defaultActivename: '',
      pagetitle: '',
      scnllist: [],
      plfllist: [],
      plEnList: [],
      //主营品类相关
      pinlei_value: -1,
      pinlei_options: [{
        value: -1,
        label: '全部'
      }],
      secord_piblei_options: [],
      //是否展开更多筛选条件
      showMore: false,
      user: {},
      show_vip: false,
      showLogin: false,
      showLanguage: true,
      loading: false,
      catId: '',

      //吸顶
      stickyFlag: false,
    };
  },
  mounted() {
    // Cascader 级联选择器: 点击label标签即可选中复选框
    setInterval(function() {
      document.querySelectorAll('.el-cascader-node__label').forEach(el => {
        el.onclick = function() {
          if(this.previousElementSibling) this.previousElementSibling.click();
        };
      });

    }, 500);
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {

    'formInline.data1': {
      handler(newVal) {
        console.log();
        if(newVal.length > 0) {
          this.scpd1 = true
        } else {
          this.scpd1 = false
        }

      },
      immediate: true
    },
    'formInline.marketAge': {
      handler(newVal) {
        console.log();
        if(newVal.length > 0) {
          this.scpd = true
        } else {
          this.scpd = false
        }

      },
      immediate: true
    },
    $route: {
      handler: function(route) {
        this.catId = route.query && route.query.catId ? route.query.catId : '';
      },
      immediate: true
    },
  },
  created() {
    this.autoLogin();
    this.$watch('source', (newValue, oldValue) => {
      // 执行相应的函数
      console.log('watch:source', newValue);
      this.changeScore(newValue)
    })
    this.formInline = {
      ...this.formInline,
      source: this.source,
    }

    this.curPage = 1
    this.pageSize = 10
    this.initAge()
    this.defaultActivename = sessionStorage.getItem('defaultActivename')
    this.status = this.$route.query.status
    this.type = this.$route.query.type
    this.select = this.status
    console.log(123123, this.type, this.status);
    if(this.type == '1') {
      this.rxmk = true
    }
    if(this.type == '2') {
      this.rxmk = true
    }
    this.biao()
  },
  computed: {
    source() {
      console.log('source', this.$store.state.source)
      return this.$store.state.source;
    },
    is_vip() {
      return this.$cookie.get('is_vip') == 'true' ? true : false
    },
    is_login() {
      return this.user && this.user.id && this.user.id > 0 ? true : false;
    }
  },
  methods: {
    // 滚动条滚动
    handleScroll(event) {
      const currentScrollPosition = event.target.scrollTop || document.body.scrollTop || document.documentElement.scrollTop;
      //   console.log('handleScroll', event.target.scrollTop, document.body.scrollTop, document.documentElement.scrollTop)
      // 根据当前滚动位置更新锚点位置信息
      if(this.stickyFlag == false) {
        if(currentScrollPosition < 330) {
          this.$emit('sticky', {
            showSticky: false,
          })
          this.stickyFlag = false;
        } else {
          this.$emit('sticky', {
            showSticky: true,
          })
          this.stickyFlag = true;
        }
      } else {
        if(currentScrollPosition < 270) {
          this.$emit('sticky', {
            showSticky: false,
          })
          this.stickyFlag = false;
        }
      }
    },
    changeNum(data) {
      switch(data) {
        case 1:
          const { minSales } = this.formInline
          if(minSales) {
            if(parseFloat(minSales) < 0) {
              this.formInline = {
                ...this.formInline,
                minSales: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                minSales: parseFloat(minSales),
              }
            }
          }
          break;
        case 2:
          const { maxSales } = this.formInline
          if(maxSales) {
            if(parseFloat(maxSales) < 0) {
              this.formInline = {
                ...this.formInline,
                maxSales: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                maxSales: parseFloat(maxSales),
              }
            }
          }
          break;
        case 3:
          const { minPrice } = this.formInline
          if(minPrice) {
            if(parseFloat(minPrice) < 0) {
              this.formInline = {
                ...this.formInline,
                minPrice: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                minPrice: parseFloat(minPrice),
              }
            }
          }

          break;
        case 4:
          const { maxPrice } = this.formInline
          if(maxPrice) {
            if(parseFloat(maxPrice) < 0) {
              this.formInline = {
                ...this.formInline,
                maxPrice: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                maxPrice: parseFloat(maxPrice),
              }
            }
          }

          break;
        case 5:
          const { minMonthSold } = this.formInline
          if(minMonthSold) {
            if(parseFloat(minMonthSold) < 0) {
              this.formInline = {
                ...this.formInline,
                minMonthSold: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                minMonthSold: parseFloat(minMonthSold),
              }
            }
          }

          break;
        case 6:
          const { maxMonthSold } = this.formInline
          if(maxMonthSold) {
            if(parseFloat(maxMonthSold) < 0) {
              this.formInline = {
                ...this.formInline,
                maxMonthSold: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                maxMonthSold: parseFloat(maxMonthSold),
              }
            }
          }

          break;
        case 7:
          const { minScore } = this.formInline
          if(minScore) {
            if(parseFloat(minScore) < 0) {
              this.formInline = {
                ...this.formInline,
                minScore: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                minScore: parseFloat(minScore),
              }
            }
          }

          break;
        case 8:
          const { maxScore } = this.formInline
          if(maxScore) {
            if(parseFloat(maxScore) < 0) {
              this.formInline = {
                ...this.formInline,
                maxScore: '',
              }
            } else {
              this.formInline = {
                ...this.formInline,
                maxScore: parseFloat(maxScore),
              }
            }
          }
          break;
      }
    },
    async autoLogin() {
      const { data: res } = await getUserInfo();
      setTimeout(() => {
        if(res.code == '0000') {
          const { data } = res;
          if(data) {
            const { user, userBenefits } = data;
            if(user) {
              console.log('confirmLoginByMobile', res, data);
              this.user = user;
              let is_vip = false;
              if(userBenefits && userBenefits.length > 0) {
                userBenefits.map(v => {
                  if(v && v.productCode && v.productCode == '1002') {
                    is_vip = v.isValid == 0 ? false : true;
                  }
                })
              }
              console.log('cookie:is_vip', is_vip);
              this.$cookie.set('is_vip', is_vip);
            } else {
            }
          } else {
          }
        } else {
        }
      }, 1000);
    },
    toLogin() {
      let jumpUrl = process.env.VUE_APP_LOGIN_URL;
      let redirectUrl = process.env.VUE_APP_MANAGE_URL + this.$route.fullPath;
      let az = Base64.encode(redirectUrl);
      window.location.href = jumpUrl + '?redirectUrl=' + az;
    },
    toBuy() {
      let jumpUrl = process.env.VUE_APP_CENTER_URL;
      window.location.href = jumpUrl;
    },
    //更改主营品类
    changeMain() {
      if(this.pinlei_value > -1) {
        //选择了主营品类
        let temp_arr = [];
        let opts = this.plfllist[this.pinlei_value].children;
        if(opts && Array.isArray(opts) && opts.length > 0) {
          opts.map(e => {
            temp_arr.push(e);
          })
        }
        this.secord_piblei_options = [].concat(temp_arr);
        this.formInline = {
          ...this.formInline,
          categoryList: [],
        }
      } else {
        //选择了全部
        this.secord_piblei_options = [];
      }
    },
    //清空排序状态
    clearSort() {
      if(this.$refs.table) {
        var columns = this.$refs.table.columns;
        columns.forEach(column => {
          if(column.sortable == 'custom') {
            column.order = undefined;
            column.multiOrder = undefined;
          }
        });
        this.$refs.table.clearFilter();
        this.$refs.table.clearSort();

        // 清空自定义排序存储
        // this.filter.SortStatements = null;
        // 此处调用后端查询方法，即可恢复默认数据
      }
    },
    //切换主营品类
    changeCascader(e) {
      let category = e;
      console.log('changeCascader', e)
      this.formInline.firstCategory = ''
      this.formInline.secondCategory = ''
      //   this.biao22(this.radio2);
    },
    //切换平台选中
    changeScore(data) {
      this.formInline = {
        ...this.formInline,
        source: data || '',
        categoryList: [],
      }
      this.initCategory();
      this.biao22(this.radio2);
    },
    async initAge() {

      let param = {};


      const { data: res } = await initAge(param);

      this.scnllist = res.data;
      let that = this;
      this.scnllist = this.scnllist.map(e => {
        if(that.type == 1) {
          if(that.status == 3) {
            if(e.value == 'season' || e.value == 'half_year' || e.value == 'one_year') {
              return { ...e, disabled: true }
            }
          }
          if(that.status == 4) {
            if(e.value == 'half_year' || e.value == 'one_year') {
              return { ...e, disabled: true }
            }
          }
        }
        if(that.type == 2) {
          if(that.status == 2 || that.status == 3) {
            if(e.value == 'half_year' || e.value == 'one_year') {
              return { ...e, disabled: true }
            }
          }
        }
        return { ...e, disabled: false }
      })
      console.log('scnllist', this.scnllist);
      this.initCategory()
    },
    async initCategory() {
      this.loading = true;
      let param = {};

      param['source'] = this.formInline.source;
      const { data: res } = await initCategory(param);

      this.plfllist = this.getTreeData(res.data);
      this.plEnList = JSON.parse(JSON.stringify(this.plfllist));
      this.pinlei_options = []
      this.loading = false;
      //   if(this.plfllist && Array.isArray(this.plfllist) && this.plfllist.length > 0) {
      //     this.plfllist.map((item, index) => {
      //       this.pinlei_options.push({
      //         label: item.name,
      //         value: item.name,
      //       })
      //     })
      //   }
      console.log('plfllist', this.plfllist, 'pinlei_options', this.pinlei_options);


    },
    getTreeData(data) {
      for(var i = 0; i < data.length; i++) {
        if(data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    getSelect(data, total, arr) {
      for(var i = 0; i < data.length; i++) {
        if(data[i].children == undefined) {
          total.push([...arr, data[i].name]);
        } else {
          arr = [...arr, data[i].name];
          this.getSelect(data[i].children, total, arr);
        }
      }
      return total;
    },
    qxcz() {
      if(!(this.is_login)) {
        this.showLogin = true
        return;
      }
      if(!(this.is_vip)) {
        this.show_vip = true
        return;
      }
      this.formInline = {
        ...this.formInline,
        sort: 'desc',
        data1: '',
        data2: '',
        title: "",
        marketAge: '',
        minPrice: "",
        maxPrice: "",
        categoryList: [],
        minSales: "",
        maxSales: "",
        minScore: "",
        maxScore: "",
        minComments: "",
        maxComments: "",
        minOnlineTime: "",
        maxOnlineTime: "",
        field: '',
        minAvg: "",
        maxAvg: "",
        minItems: "",
        maxItems: "",
        maxMonthSold: '',
        minMonthSold: '',
        firstCategory: '',
        secondCategory: '',
      };
      this.curPage = 1
      this.pageSize = 10
      this.radio2 = 3
      this.biao()
    },
    details() {
      // this.$router.push({
      //   path: "/workbench/details",
      //   query: {},
      // });
    },
    biaoaa() {
      if(!(this.is_login)) {
        this.showLogin = true
        return;
      }
      if(!(this.is_vip)) {
        this.show_vip = true
        return;
      }
      this.curPage = 1
      this.pageSize = 10
      this.biao()
    },
    biao() {

      if(this.type == 1) {
        if(this.status == 1) {
          this.pagetitle = '商品热销榜'
          this.listHotSaleProducts(3)
        }
        if(this.status == 2) {
          this.listDaySaleProducts(3)
          this.pagetitle = '日销排行榜'
        }
        if(this.status == 3) {
          this.listHotNewProducts(3)
          this.pagetitle = '新品热销榜'
        }
        if(this.status == 4) {
          this.listPotentialProducts(3)
          this.pagetitle = '潜力爆品榜'
        }

      }
      if(this.type == 2) {
        if(this.status == 1) {
          this.listHotSaleShop(3)
          this.pagetitle = '热销店铺榜'
        }
        if(this.status == 2) {
          this.listPersistHotSaleShop(3)
          this.pagetitle = '持续热店榜'
        }
        if(this.status == 3) {
          this.listNewShopSale(3)
          this.pagetitle = '新店热销榜'
        }

      }
      if(this.type == 3) {
        if(this.status == 1) {
          this.getSearchWordsList(0)
          this.pagetitle = '跨境热搜词'
        }
        if(this.status == 2) {
          this.getSearchWordsList(1)
          this.pagetitle = '热搜飙升词'
        }
        if(this.status == 3) {
          this.getSearchWordsList(2)
          this.pagetitle = '机会蓝海词'
        }

      }
      if(this.type == 4) {
        if(this.status == 1) {
          this.marketlist(0)
          this.pagetitle = '品类热销榜'
        }
        if(this.status == 2) {
          this.marketlist(1)
          this.pagetitle = '品类飙升榜'
        }
        if(this.status == 3) {
          this.marketlist(2)
          this.pagetitle = '蓝海品类'
        }

      }
    },
    aac() {
      if(this.radio2 != 3) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.radio2 = 3;
          return;
        }
      }
      console.log(123, this.radio2);
      this.sort = 'desc';
      this.clearSort();
      this.biao22(this.radio2);
    },
    biao22(e) {
      if(this.type == 1) {
        if(this.status == 1) {
          this.listHotSaleProducts(e)
          this.pagetitle = '商品热销榜'
        }
        if(this.status == 2) {
          this.listDaySaleProducts(e)
          this.pagetitle = '日销排行榜'
        }
        if(this.status == 3) {
          this.listHotNewProducts(e)
          this.pagetitle = '新品热销榜'
        }
        if(this.status == 4) {
          this.listPotentialProducts(e)
          this.pagetitle = '潜力爆品榜'
        }

      }

      if(this.type == 2) {
        if(this.status == 1) {
          this.listHotSaleShop(e)
          this.pagetitle = '热销店铺榜'
        }
        if(this.status == 2) {
          this.field = 'hot_sale_index'
          this.listPersistHotSaleShop(e)
          this.pagetitle = '持续热店榜'
        }
        if(this.status == 3) {
          this.listNewShopSale(e)
          this.pagetitle = '新店热销榜'
        }

      }
      if(this.type == 3) {
        if(this.status == 1) {
          this.getSearchWordsList(0, e)
        }
        if(this.status == 2) {
          this.getSearchWordsList(1, e)
        }
        if(this.status == 3) {
          this.getSearchWordsList(2, e)
        }

      }
      if(this.type == 4) {
        if(this.status == 1) {
          this.marketlist(0, e)
        }
        if(this.status == 2) {
          this.marketlist(1, e)
        }
        if(this.status == 3) {
          this.marketlist(2, e)
        }

      }
    },
    llgl(e) {
      if(!(this.is_vip)) {
        this.show_vip = true;
        return;
      }
      sessionStorage.setItem('ll1', e.firstCategory)
      sessionStorage.setItem('ll2', e.secondCategory)
      window.sessionStorage.setItem("activeMenu", '/buffer?type=1&status=1')
      window.sessionStorage.setItem("defaultActivename", '商品')



      this.$router.push({
        path: "/buffer",
        query: {
          type: 1,
          status: 1
        },
      });

    },
    scgl(e) {
      if(!(this.is_vip)) {
        this.show_vip = true;
        return;
      }
      console.log(e);
      sessionStorage.setItem('fj', e.catLevel)
      sessionStorage.setItem('scc', e.catName)
      window.sessionStorage.setItem("activeMenu", '/buffer?type=1&status=1')
      window.sessionStorage.setItem("defaultActivename", '商品')

      this.$router.push({
        path: "/buffer",
        query: {
          type: 1,
          status: 1,
          catId: e.catId
        },
      });

    },
    async toagetSaleAmtAndSumTrend(data, index) {
      let param = {};
      param["dateType"] = 1
      // param["id"] =this.$route.query.id;
      param["goodsId"] = data.goodsId;
      const { data: res } = await agetSaleAmtAndSumTrend(param);
      //   this.agetRelateFlowList(this.date_type)
      //   this.getStatisticDate(2)
      let dataList = res.data || []
      this.$set(this.tableData, index, {
        ...this.tableData[index],
        goodsTrends: dataList,
      })
      console.log('toagetSaleAmtAndSumTrend', this.tableData[index]);
    },
    async listHotSaleProducts(e) {
      this.loading = true;
      let param = {};
      param["type"] = this.type
      param["page"] = this.curPage
      if(this.curPage >= 4) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listHotSaleProducts()

          return;
        }
      }

      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;

      param["firstCategory"] = this.formInline.firstCategory;
      param["secondCategory"] = this.formInline.secondCategory;
      console.log('formInline', this.formInline, this.formInline.categoryList)
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }
      if(this.catId) {
        param["catId"] = this.catId
      }
      param["categoryList"] = this.formInline.categoryList;
      if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
        param['catId'] = '';
      }

      param["marketAge"] = this.formInline.marketAge;
      param["minComments"] = this.formInline.minComments;
      param["maxComments"] = this.formInline.maxComments;

      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minPrice"] = this.formInline.minPrice;
      param["maxPrice"] = this.formInline.maxPrice;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["minScore"] = this.formInline.minScore;
      param["maxScore"] = this.formInline.maxScore;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param["dateType"] = this.radio2;


      const { data: res } = await listHotSaleProducts(param);
      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;
        let aa = []
        res.data.records.forEach((z, i) => {
          z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa;
        this.tableData.forEach((z, i) => {
          this.toagetSaleAmtAndSumTrend(z, i)
        })
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }

    },

    async listDaySaleProducts(e) {
      this.loading = true;
      let param = {};

      param["page"] = this.curPage
      if(this.curPage >= 2) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listDaySaleProducts()
          return;
        }
      }
      console.log('categoryList', this.formInline.categoryList)
      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param["marketAge"] = this.formInline.marketAge;
      param["minComments"] = this.formInline.minComments;
      param["maxComments"] = this.formInline.maxComments;
      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minPrice"] = this.formInline.minPrice;
      param["maxPrice"] = this.formInline.maxPrice;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["minScore"] = this.formInline.minScore;
      param["maxScore"] = this.formInline.maxScore;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;

      param["dateType"] = 1;
      const { data: res } = await listDaySaleProducts(param);
      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;
        let aa = []
        res.data.records.forEach(z => {
          z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa;
        this.tableData.forEach((z, i) => {
          this.toagetSaleAmtAndSumTrend(z, i)
        })
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }

    },
    async listHotNewProducts(e) {
      this.loading = true;

      let param = {};
      param["dateType"] = this.radio2;
      param["page"] = this.curPage
      if(this.curPage >= 2) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listHotNewProducts()
          return;
        }
      }
      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;
      param["sort"] = this.sort;
      param["field"] = this.field;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;
      param["marketAge"] = this.formInline.marketAge;
      param["minComments"] = this.formInline.minComments;
      param["maxComments"] = this.formInline.maxComments;
      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minPrice"] = this.formInline.minPrice;
      param["maxPrice"] = this.formInline.maxPrice;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["minScore"] = this.formInline.minScore;
      param["maxScore"] = this.formInline.maxScore;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;

      const { data: res } = await listHotNewProducts(param);

      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;
        let aa = []
        res.data.records.forEach(z => {
          z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa;
        this.tableData.forEach((z, i) => {
          this.toagetSaleAmtAndSumTrend(z, i)
        })
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }
    },
    async listPotentialProducts(e) {
      this.loading = true;

      let param = {};
      param["dateType"] = this.radio2;
      param["page"] = this.curPage
      if(this.curPage >= 2) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listPotentialProducts()
          return;
        }
      }
      if(this.curPage >= 11) {
        if(!this.is_svip) {
          this.show_vip = true;
          this.curPage = 1;
          this.listPotentialProducts()
          return;
        }
      }

      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param["marketAge"] = this.formInline.marketAge;
      param["minComments"] = this.formInline.minComments;
      param["maxComments"] = this.formInline.maxComments;
      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minPrice"] = this.formInline.minPrice;
      param["maxPrice"] = this.formInline.maxPrice;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["minScore"] = this.formInline.minScore;
      param["maxScore"] = this.formInline.maxScore;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;

      const { data: res } = await listPotentialProducts(param);

      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;
        let aa = []
        res.data.records.forEach(z => {
          z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa;
        this.tableData.forEach((z, i) => {
          this.toagetSaleAmtAndSumTrend(z, i)
        })
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }
    },
    async listHotSaleShop(e) {
      this.loading = true;

      let param = {};
      param["dateType"] = this.radio2;
      param["page"] = this.curPage

      if(this.curPage >= 2) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listHotSaleShop()
          return;
        }
      }

      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param["marketAge"] = this.formInline.marketAge;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;
      param["maxAvg"] = this.formInline.maxPrice;
      param["minAvg"] = this.formInline.minPrice;;
      param["maxComments"] = this.formInline.maxComments;
      param["minComments"] = this.formInline.minComments;
      param["maxFollwers"] = this.formInline.maxFollwers;
      param["minFollwers"] = this.formInline.minFollwers;
      param["maxItems"] = this.formInline.maxItems;
      param["minItems"] = this.formInline.minItems;
      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minRank"] = this.formInline.minScore;
      param["maxRank"] = this.formInline.maxScore;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["maxSoldAmount"] = '';
      param["minSoldAmount"] = '';
      param["platform"] = '';
      param["shopEndDate"] = '';
      param["shopStartDate"] = '';

      const { data: res } = await listHotSaleShop(param);
      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;

        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }
    },
    async listPersistHotSaleShop(e) {
      this.loading = true;

      let param = {};
      param["dateType"] = this.radio2;
      param["page"] = this.curPage

      if(this.curPage >= 2) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listPersistHotSaleShop()
          return;
        }
      }

      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param["marketAge"] = this.formInline.marketAge;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;
      param["maxAvg"] = this.formInline.maxPrice;
      param["minAvg"] = this.formInline.minPrice;;
      param["maxComments"] = this.formInline.maxComments;
      param["minComments"] = this.formInline.minComments;
      param["maxFollwers"] = this.formInline.maxFollwers;
      param["minFollwers"] = this.formInline.minFollwers;
      param["maxItems"] = this.formInline.maxItems;
      param["minItems"] = this.formInline.minItems;
      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minRank"] = this.formInline.minScore;
      param["maxRank"] = this.formInline.maxScore;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["maxSoldAmount"] = '';
      param["minSoldAmount"] = '';
      param["platform"] = '';
      param["shopEndDate"] = '';
      param["shopStartDate"] = '';

      const { data: res } = await listPersistHotSaleShop(param);
      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;

        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }
    },
    async listNewShopSale(e) {
      this.loading = true;

      let param = {};
      param["dateType"] = this.radio2;
      param["page"] = this.curPage

      if(this.curPage >= 2) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.listNewShopSale()
        }
      }
      param["pagesize"] = this.pageSize
      param["source"] = this.formInline.source;
      param["title"] = this.formInline.title;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param["marketAge"] = this.formInline.marketAge;
      param["maxMonthSold"] = this.formInline.maxMonthSold;
      param["minMonthSold"] = this.formInline.minMonthSold;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;
      param["maxAvg"] = this.formInline.maxPrice;
      param["minAvg"] = this.formInline.minPrice;;
      param["maxComments"] = this.formInline.maxComments;
      param["minComments"] = this.formInline.minComments;
      param["maxFollwers"] = this.formInline.maxFollwers;
      param["minFollwers"] = this.formInline.minFollwers;
      param["maxItems"] = this.formInline.maxItems;
      param["minItems"] = this.formInline.minItems;
      param["minOnlineTime"] = this.formInline.data1[0];
      param["maxOnlineTime"] = this.formInline.data1[1];
      param["minRank"] = this.formInline.minScore;
      param["maxRank"] = this.formInline.maxScore;
      param["minSales"] = this.formInline.minSales;
      param["maxSales"] = this.formInline.maxSales;
      param["maxSoldAmount"] = '';
      param["minSoldAmount"] = '';
      param["platform"] = '';
      param["shopEndDate"] = '';
      param["shopStartDate"] = '';
      const { data: res } = await listNewShopSale(param);
      if(res.code == '0000') {
        this.tableData = res.data.records;
        this.loading = false;

        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }
    },
    async exportRecords() {
      this.loading = true;

      let param = {};


      param["page"] = this.curPage
      param["pagesize"] = this.pageSize
      param["keyword"] = this.formInline.title;

      param["categoryList"] = this.formInline.categoryList;
      param["marketAge"] = this.formInline.marketAge;
      param["maxAvgPrice"] = this.formInline.maxAvgPrice;
      param["minAvgPrice"] = this.formInline.minAvgPrice;
      param["maxProductNum"] = this.formInline.maxItems;
      param["minProductNum"] = this.formInline.minItems;
      param["maxSales"] = this.formInline.maxSales;
      param["minScore"] = this.formInline.minScore;
      param["maxShopNum"] = this.formInline.maxShopNum;
      param["minShopNum"] = this.formInline.minShopNum;

      param["maxSoldAmount"] = this.formInline.maxSoldAmount;
      param["minSoldAmount"] = this.formInline.minSoldAmount;

      // param["dateType"] = z
      param["sort"] = this.sort;
      param["field"] = this.field;
      // param['wordType'] = m
      const { data: res } = await exportRecords(param);
      this.loading = false;

      // this.tableData = res.data.records;
      // let aa = []
      // res.data.records.forEach(z => {
      //   z.secondCategory = z.secondCategory.split(',')
      //   aa.push(z)
      // })
      // this.tableData = aa
      // console.log('tableData',this.tableData)
      // this.total = res.data.total;
      // this.curPage = res.data.current;
      // this.pageSize = res.data.size;
    },

    async getSearchWordsList(m) {
      this.loading = true;

      let param = {};


      param["page"] = this.curPage
      if(m == 1 || m == 0) {
        if(this.curPage >= 4) {
          if(!(this.is_vip)) {
            this.show_vip = true;
            this.curPage = 1;
            this.getSearchWordsList(m)
            return;
          }
        }
      } else {
        if(this.curPage >= 2) {
          if(!(this.is_vip)) {
            this.show_vip = true;
            this.curPage = 1;
            this.getSearchWordsList(m)
          }
          return;
        }
        if(this.curPage >= 11) {
          if(!this.is_svip) {
            this.show_vip = true;
            this.curPage = 1;
            this.getSearchWordsList(m)
            return;
          }
        }
      }


      param["pagesize"] = this.pageSize
      param["keyword"] = this.formInline.title;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;
      param["marketAge"] = this.formInline.marketAge;
      param["maxAvgPrice"] = this.formInline.maxAvgPrice;
      param["minAvgPrice"] = this.formInline.minAvgPrice;
      param["maxProductNum"] = this.formInline.maxItems;
      param["minProductNum"] = this.formInline.minItems;
      param["maxSales"] = this.formInline.maxSales;
      param["minScore"] = this.formInline.minScore;
      param["maxShopNum"] = this.formInline.maxShopNum;
      param["minShopNum"] = this.formInline.minShopNum;

      param["maxSoldAmount"] = this.formInline.maxSoldAmount;
      param["minSoldAmount"] = this.formInline.minSoldAmount;

      param["dateType"] = this.radio2;
      param["sort"] = this.sort;
      param["field"] = this.field;
      param['wordType'] = m
      const { data: res } = await getSearchWordsList(param);
      if(res.code == '0000') {
        // this.tableData = res.data.records;
        let aa = []
        res.data.records.forEach(z => {
          z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa
        this.loading = false;

        console.log('tableData', this.tableData)
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }

    },
    async marketlist(m) {

      this.loading = true;

      let param = {};


      param["page"] = this.curPage

      if(this.curPage >= 4) {
        if(!(this.is_vip)) {
          this.show_vip = true;
          this.curPage = 1;
          this.marketlist(m)
          return;
        }
      }

      param["pagesize"] = this.pageSize
      param["keyword"] = this.formInline.title;
      param['source'] = this.formInline.source;
      //   let a = [];
      //   if(this.pinlei_value > -1) {
      //     let first = this.plfllist[this.pinlei_value].name;
      //     if(this.formInline.categoryList && this.formInline.categoryList.length > 0) {
      //       for(let i = 0; i < this.formInline.categoryList.length; i++) {
      //         let t = this.formInline.categoryList[i];
      //         a.push([first].concat(t))
      //       }
      //     } else {
      //       a = this.getSelect(this.plfllist[this.pinlei_value].children, [], [this.plfllist[this.pinlei_value].name])
      //     }
      //   }

      param["categoryList"] = this.formInline.categoryList;
      param["marketAge"] = this.formInline.marketAge;
      param["maxAvgPrice"] = this.formInline.maxAvgPrice;
      param["minAvgPrice"] = this.formInline.minAvgPrice;
      param["maxProductNum"] = this.formInline.maxItems;
      param["minProductNum"] = this.formInline.minItems;
      param["maxSales"] = this.formInline.maxSales;
      param["minSales"] = this.formInline.minSales;
      param["maxShopNum"] = this.formInline.maxShopNum;
      param["minShopNum"] = this.formInline.minShopNum;

      param["maxSoldAmount"] = this.formInline.maxSoldAmount;
      param["minSoldAmount"] = this.formInline.minSoldAmount;

      param["dateType"] = this.radio2
      param["sort"] = this.sort;
      param["field"] = this.field;
      param['wordType'] = m

      const { data: res } = await marketlist(param);
      if(res.code == '0000') {
        let aa = []
        res.data.records.forEach(z => {
          // z.secondCategory = z.secondCategory.split(',')
          aa.push(z)
        })
        this.tableData = aa
        this.loading = false;
        this.total = res.data.total;
        this.curPage = res.data.current;
        this.pageSize = res.data.size;
      } else {
        this.loading = false;
        if(res.code == '8001') {
          this.show_vip = true;
          return;
        }
        this.$message.error(res.msg || "失败");
      }

    },
    sortByDate(e) {
      console.log(123, e.order);
      this.field = e.prop;
      if(e.order == 'ascending') {
        this.sort = 'asc'
      } else if(e.order == 'descending') {
        this.sort = 'desc'
      } else {
        this.sort = 'desc'
        this.field = ''
      }
      this.biao22(this.radio2)
    },
    spxq(e) {
      //   this.$router.push({
      //     path: "/productid",
      //     query: {
      //       id: e
      //     },
      //   });
      if(!(this.is_vip)) {
        this.show_vip = true;
        return;
      }
      const routeData = this.$router.resolve({ path: '/productid', query: { id: e } }).href;
      console.log('routeData', routeData)
      //   const url = baseUrl + '/productid?id='+ e;
      //   // 打开新窗口
      window.open(routeData, '_blank');
    },
    shopid(e) {
      //   this.$router.push({
      //     path: "/shopdeild",
      //     query: {
      //       id: e
      //     },
      //   });
      if(!(this.is_vip)) {
        this.show_vip = true;
        return;
      }
      const routeData = this.$router.resolve({ path: '/shopdeild', query: { id: e } }).href;
      console.log('routeData', routeData)
      //   const url = baseUrl + '/productid?id='+ e;
      //   // 打开新窗口
      window.open(routeData, '_blank');
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val

      this.biao()

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.biao()
    },


    // 新建计划弹框
    project() {
      this.upproject = true
      this.$refs[formName].resetFields();
    },
    //选择状态
    statusBoutton(e) {
      this.formInline.status = e.id;
    },
    // 结束按钮
    finish(e) {
      this.dialogVisible = true
      this.finishId = e.id
    },
    // 确认结束
    finishBont() {
      this.dialogVisible = false
      // this.finishId 当前选择结束的ID
    },
    // 新增计划
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
  <style lang="less" scoped>
.pd {
  &-search {
    &-switch {
      /deep/ .el-switch__label {
        font-size: 16px;
      }
      /deep/ .el-switch__label * {
        font-size: 16px;
      }
    }
  }
  &-table {
    /deep/.el-table__header-wrapper {
      position: fixed;
      top: 0;
      z-index: 999;
    }
  }
}
/deep/ .dialogwidth {
  min-width: 300px;
}
.border-selected {
  border: 3px solid #4095e5;
  border-radius: 16px !important;
}
.diadin {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 17px;
  font-family: PingFang SC-Regular;
  position: relative;
  /deep/ .el-table .el-table__cell {
    padding: 4px 0;
    border-right: none;
  }
}
.border {
  border-bottom: 1px solid #eeeeee;
}
/deep/.el-select {
  width: 100%;
}
.actoin {
  color: #409eff !important;
}
/deep/.el-form-item__content {
  width: 82%;
}
/deep/.el-input-group__append {
  width: 40%;
}
/deep/.el-table__header th,
.el-table__header tr {
  font-weight: 400;
  color: #333 !important;
}
// /deep/.el-input__inner{
//   background-color: #f5f7fa !important;
//   height: 28px !important;
//   width: 100%;
// }
// /deep/.el-input__icon{
//   line-height: 28px !important;
// }
.titlable {
  width: 130px;
  min-width: 100px;
  text-align: right;
  padding-right: 10px;
}
a {
  text-decoration: none !important;
  color: #606266 !important;
}
/deep/.el-table__header th,
.el-table__header tr {
  background-color: #f7f8fa;
  padding: 25px 0;
}
/deep/.el-radio-button--medium .el-radio-button__inner {
  padding: 7px 37px;
  height: 29px;
}
/deep/.el-radio-button__inner {
  background: #f5f5fd;
  border: none;
  font-family: PingFang SC-Regular;
  color: #959595;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #4975e9;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-left: none;
}
</style>